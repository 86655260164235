export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "PLACE_HOLDERS": {
          
        },
        "LABELS": {
          "AMOUNT_SELECTED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Monto total seleccionado: ", _interpolate(_named("amount"))])},
          "ADVANCE_SERACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda por venta"])},
          "FOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio"])},
          "IMPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe"])}
        },
        "BUTTONS": {
          "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
          "BILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturar"])}
        },
        "TOOLTIPS": {
          
        },
        "MESSAGES": {
          "INDICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes filtrar por una venta para facturar"])},
          "NO_DATA_PRODUCTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontro una venta valida para facturar"])},
          "TITLE_BILL_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factura creada"])}
        }
      }
    }
  })
}
