
import { defineComponent, computed } from "vue";
import InputText from "@/components/forms/InputText.vue" 
import Switch from "@/components/forms/Switch.vue"
import Select from "@/components/forms/Select.vue" 
import { InputTextMaskTypes } from '@/core/enums/input-text-mask-types';
import { getModule } from "vuex-module-decorators";
import NewBillModule from "@/store/modules/bill/modules/new-bill";
export default defineComponent({
    components:{
        InputText
        , Select
        , Switch
    },
    setup(){
        const moduleForm = getModule(NewBillModule);

        const useOptions = computed(() => moduleForm.selects.cfdiOptions);
        const wayToPayOptions = computed(() => moduleForm.selects.wayToPayOptions);
        const paymentMethods = computed(() =>  moduleForm.selects.paymentMethodsOptions);
        const regimeOptions = computed(() => moduleForm.selects.regimeOptions);
        
        const showControlLessThan = computed(() => moduleForm.showControlLessThan);
        return {
            InputTextMaskTypes
            , useOptions
            , wayToPayOptions
            , paymentMethods
            , regimeOptions
            , showControlLessThan
        }
    }
})
