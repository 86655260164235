export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TITLES": {
          "BILL_FOR_SALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Facturación Por Venta"])},
          "BILL_GLOBAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Facturación Global"])}
        },
        "SUB_TITLE_BILL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El monto completo de la factura es de ", _interpolate(_named("amount")), "."])}
      }
    }
  })
}
