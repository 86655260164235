
import { defineComponent, computed } from "vue";
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue";
import BillProductsForm from './BillProductsForm.vue';

import { formatNumeric } from "@/core/shared/functions/Formatter"
import { getModule } from "vuex-module-decorators";
import NewBillModule from "@/store/modules/bill/modules/new-bill";

export default defineComponent({
    components: {
        DynamicContentModal
        , BillProductsForm
    },
    setup() {
        //const { billInfo } = toRefs(props);
        const moduleForm = getModule(NewBillModule);
        const isGlobalBill = computed(() => moduleForm.isBillingGlobal);
        const amountToBill = computed(() => moduleForm.amountToBill);
        const cleanForm = () => {
            //emitter.emit("resetBillForm"); se llama cuando s eguarda el reguistro
        }
        return {
            cleanForm
            , formatNumeric
            , isGlobalBill
            , amountToBill
            //, searchBillInfo
        }
    },
})
