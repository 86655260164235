
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        name: {
            type: [String, Number]
            , required: true
        }
        , value: {
            type: String
            , default: ''
        }
    }
})
