
import { defineComponent, computed,ref, toRefs, watch } from "vue"
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import VuePdfEmbed from 'vue-pdf-embed'
import { base64ToBlob } from "@/core/shared/functions/FileTools"


export default defineComponent({
  name: "WebViewer",
    components:{
        DynamicContentModal,
        VuePdfEmbed
    },
    props: { 
      //la base64 no es solo eso... tiene la data:application/pdf;base64, dentro de su contenido
      base64: { 
        type: String,
        required: true 
        },
      titulo: {
        type: String,
        required: true 
      },
      fileName: {
        type: String
        , required: true
      }
    },
    data() {
      return {
         annotationIconsPath: '/node_modules/pdfjs-dist/web/images/'
      }
    },
    setup(props) {
    let pdf = ref("");

    const { base64, fileName } = toRefs(props);

    watch(base64, (value) => {
      pdf.value = value;
    })


    const downloadFile = () => {
      const a = document.createElement("a");
      a.href = base64.value; 
      a.download = fileName.value; 
      a.click();
    }

    return {
      downloadFile,
      pdf,
      pdfbase64: computed(() => { return pdf.value })
    }
    }
})

