import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row g-4 mb-8" }
const _hoisted_2 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_3 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_4 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_5 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_6 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_7 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_8 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_9 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_10 = { class: "col-md-4 col-sm-12 fv-row" }
const _hoisted_11 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_12 = { class: "col-md-4 col-sm-12 fv-row" }
const _hoisted_13 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_14 = { class: "col-md-4 col-sm-12 fv-row" }
const _hoisted_15 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_16 = { class: "col-md-2 col-sm-6 fv-row" }
const _hoisted_17 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_18 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_19 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_20 = {
  key: 0,
  class: "col-md-12 col-sm-12 fv-row d-inline-flex align-items-center"
}
const _hoisted_21 = { class: "fs-6 fw-bold ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Switch = _resolveComponent("Switch")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("LABELS.NAME")), 1),
        _createVNode(_component_InputText, { name: 'name' })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("LABELS.BUSINESS_NAME")), 1),
        _createVNode(_component_InputText, { name: 'businessName' })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("LABELS.RFC")), 1),
        _createVNode(_component_InputText, {
          name: 'rfc',
          mask: _ctx.InputTextMaskTypes.Uppercase
        }, null, 8, ["mask"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("LABELS.REGIMEN")), 1),
        _createVNode(_component_Select, {
          name: 'regimeId',
          options: _ctx.regimeOptions
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("LABELS.USE_CFDI")), 1),
        _createVNode(_component_Select, {
          name: 'typeUseCFDIId',
          options: _ctx.useOptions
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("LABELS.PAYMENT_METHOD")), 1),
        _createVNode(_component_Select, {
          name: 'paymentMethodId',
          options: _ctx.paymentMethods
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("LABELS.WAY_TO_PAY")), 1),
        _createVNode(_component_Select, {
          name: 'wayToPayId',
          options: _ctx.wayToPayOptions
        }, null, 8, ["options"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("LABELS.ZIP_CODE")), 1),
        _createVNode(_component_InputText, { name: 'cp' })
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("LABELS.EMAIL")), 1),
        _createVNode(_component_InputText, { name: 'email' })
      ]),
      (_ctx.showControlLessThan)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createVNode(_component_Switch, { name: 'lLesthan' }),
            _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("LABELS.lLesthan")), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}