
import { computed, defineComponent, ref, watch, onMounted } from "vue";
import FieldsBill from "../../bill/components/FieldsBill.vue";
//import InputNumber from "@/components/forms/InputNumber.vue" 
import * as Validations from "yup";
import { Form } from "vee-validate";
import NewBillModule from "@/store/modules/bill/modules/new-bill";
import { getModule } from "vuex-module-decorators";
import { RfcFormat } from "@/core/shared/ValidationRegex";
import { hideModalById } from "@/core/helpers/dom";
import NewBillRequest from "@/models/bill/NewBillRequest";
//este componente debe llamar billSaleForm
import emitter from "@/core/emitters";
import SwalMessageService from "@/core/services/SwalMessageService";
import { SwalIconType } from "@/core/enums/swal-icon-type";
import router from "@/router";
import { RouteNames } from "@/router/route-names";
export default defineComponent({
    components: {
        FieldsBill
        , Form
    },
    setup(){
        const moduleForm = getModule(NewBillModule);
        const form = ref();
        const messageService = new SwalMessageService();
        
        const validationSchema = computed(() => Validations.object().shape({
                name: Validations.string().default('').required()
                , businessName: Validations.string().default('').required()
                , rfc: Validations.string().required().matches(RfcFormat, { excludeEmptyString: true, message: 'Formato icorrecto de RFC' })
                , typeUseCFDIId: Validations.string().default('').required()
                , paymentMethodId: Validations.string().default('').required()
                , wayToPayId: Validations.string().default('').required()
                , cp: Validations.string().default('').required()
                , email: Validations.string().email().required()
                , regimeId: Validations.string().default('').required()
                , lLesthan:  Validations.boolean().default(false)
            }));

        const saveBill = (values: NewBillRequest, {resetForm}) =>{
            //guarda la factura
            if (moduleForm.isBillingGlobal) {
                 moduleForm.SAVE_GLOBAL_BILL(values)
                    .then((isSuccess) => {
                        //ajustar
                        if (isSuccess) {
                            resetForm();
                            //ocultar modal
                            hideModalById('#new-bill-sale')
                            //mensaje de exito
                        }
                    })
            } else { 
                 moduleForm.SAVE_BILL_GENERAL(values)
                .then((isSuccess) => {
                    //ajustar
                    if (isSuccess) {
                        resetForm();
                        //ocultar modal
                        hideModalById('#new-bill-sale')
                        //mensaje de exito
                    }
                })
            }

        }
        
        onMounted(() => {
             emitter.on('showBillCreated', (payload) => {
                //mostrar la opcion para abrir la factura en el modulo de creadas
                messageService.confirm(SwalIconType.Success, 'Exito al crear la factura. <br> ¿Desea ir a ver la factura creada para poder descargar sus archivos?')
                .then(isYes => {
                    //enviar mediante el route a la pagina facturas creadas mediante el numBill
                    if (isYes) {
                        router.push({
                            query: {
                                numBill: payload?.billFolio
                            }
                            , name: RouteNames.BillCreated
                        })
                    }

                })
            })
            /*emitter.on('resetBillForm', () => {
                bills.value = [{
                    amount: 0
                    , positionNumber: 1
                    , saleId: ''
                    , saleFolio: ''
                }]
                form.value.resetForm();
                hideModalById('#new-bill-products');//TODO posiblemente se quite
            })*/
        })

        watch(() => moduleForm.preselectData, (newValues) => {
            form.value.setValues({
                useCfdiId: newValues.typeUseCFDIId
                , paymentMethodId: newValues.paymentMethodId
                , wayToPayId: newValues.wayToPayId
                , regimeId: newValues.regimeId
            })
        })
        watch(() => moduleForm.getBillInfo, (newValues) => { 
            if (newValues.name != undefined && newValues.name != undefined) {
                form.value.setValues({
                    ...newValues
                })
            }
        })
        return {
            saveBill
            , validationSchema
            , form
        }
    }
})
