export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "BUSINESS_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón Social"])},
          "RFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R.F.C"])},
          "USE_CFDI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C.F.D.I."])},
          "ZIP_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codigo Postal"])},
          "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo"])},
          "REGIMEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regimen"])},
          "PAYMENT_METHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma de Pago"])},
          "WAY_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de Pago"])},
          "lLesthan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe factura menores a $2,000.0"])},
          "PORCENTAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje"])}
        }
      }
    }
  })
}
