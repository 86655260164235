import { getMessageError, getMessageHeader } from '@/core/helpers/messageFromPulmeros';
import { translate } from '@/core/plugins/i18n';
import NewClient from '@/models/clients/form/NewClient';
import PaginationRequest from '@/models/general/PaginationRequest';
import PaginationResponse from '@/models/general/PaginationResponse';
import FilterFolio from '@/models/general/Paginations/Filters/FilterFolio';
import TablePaginationRequest from '@/models/general/TablePaginationRequest';
import OptionSelectClient from '@/models/sales/clientsSale';
import FilterSales from '@/models/sales/FilterSales';
import NewSale from '@/models/sales/NewSale';
import NewSaleDetail from '@/models/sales/NewSaleDetail';
import NewSaleResponse from '@/models/sales/NewSaleResponse';
import SaleInfoWithBillInfo from '@/models/sales/SaleInfoWithBillInfo';
import SaleRow from '@/models/sales/SaleRows';
import SenToCash from '@/models/sales/SenToCash';
import SenToSale from '@/models/sales/SenToSale';
import UpdateSaleDetail from '@/models/sales/UpdateSaleDetail';
import { AxiosResponse } from 'axios';
import ApiService from '../ApiService';
import GenericCrudService from "../general/GenericCrudService";
import SwalMessageService from '../SwalMessageService';
import SalesTableService from './interfaces/SalesTableService';
import TableBillProductsService from './interfaces/TableBillProductsService';
import FiltersSearchToBill from '@/models/bill/filters/FiltersSearchToBill';
import CancelSale from '@/models/sales/CancelSale';

export default class SaleService 
    extends GenericCrudService<NewSale<NewSaleDetail>, SaleRow> 
    implements SalesTableService, TableBillProductsService {

    constructor(){
        super("/Sales", new SwalMessageService() );
    }

     /**
     * Guarda los datos de una nueva venta.
     * @param form datos de la entidad nueva
     * @returns indicador del guardado correcto
     */
     async saveSale(form: NewSale<NewSaleDetail>): Promise<AxiosResponse<NewSaleResponse>> {
        return ApiService.post<NewSaleResponse>(this.apiController,
            form
        ).then( (resp) => {
            return resp;
        }).catch((error) => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async updateSale(form: NewSale<UpdateSaleDetail>): Promise<AxiosResponse<NewSaleResponse>> {
        return ApiService.put(this.apiController + `/${form.id}`
            , form
        ).then( (resp) => {
            if(resp.status > 200 && resp.status < 400){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return resp;
        }).catch((error) => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    
    /**
     * Recupera información de la venta para el grid
     * @param request filtros para recuperar ventas
     * @returns listado de ventas
     */
    async getTableInfo(paginator: PaginationRequest, filter: FilterSales): Promise<AxiosResponse<PaginationResponse<SaleRow>>> {
        return  ApiService.get<PaginationResponse<SaleRow>>(this.apiController, 
            `GetPagedList?`
            + `CurrentPage=${paginator.currentPage}`
            + `&PageSize=${paginator.pageSize}`
            + (filter.rfc != undefined ? `&Criteria.Name=${filter.rfc}` : '')
            + (filter.status != undefined && filter.status != '-1' ? `&Criteria.SaleState=${filter.status}` : '')
            + (filter.invoice != undefined && filter.invoice != '' ? `&Criteria.Invoice=${filter.invoice}` : '')
           
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async getTableQuote(paginator: PaginationRequest, filter: FilterSales): Promise<AxiosResponse<PaginationResponse<SaleRow>>> {
        console.log(this.apiController);
        return  ApiService.get<PaginationResponse<SaleRow>>(this.apiController,
            `GetPagedListSaleQuote?` 
            + `CurrentPage=${paginator.currentPage}`
            + `&PageSize=${paginator.pageSize}`
            + (filter.rfc != undefined ? `&Criteria.Name=${filter.rfc}` : '')
            + (filter.status != undefined && filter.status != '-1' ? `&Criteria.SaleState=${filter.status}` : '')
            + (filter.invoice != undefined && filter.invoice != '' ? `&Criteria.SaleQuoteFolio=${filter.invoice}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async searchClientOptions(filterName: string): Promise<AxiosResponse<PaginationResponse<OptionSelectClient[]>>> {
        return  ApiService.get<PaginationResponse<OptionSelectClient[]>>("Customer", 
            `combo?`
            + `criteria=${filterName}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    /**
     * Activa una venta
     * @param id identificador de la venta
     * @returns indicador
     */
    async activeSale(id: string): Promise<AxiosResponse<boolean>>{
        return  ApiService.put(this.apiController + `/${id}/activate`
            , id
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    } 

    /**
     * Activa una venta
     * @param id identificador de la venta
     * @returns indicador
     */
     async disableSale(data: CancelSale): Promise<AxiosResponse<boolean>>{
        return  ApiService.put(this.apiController + `/${data.saleId}/Cancel`
            , data
        ).then( resp => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            
            return resp;
        }).catch(error => {
            console.log(error);
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    /**
     * Mandar a pagos una venta
     * @param id identificador de la venta
     * @returns indicador
     */
     async senToPaySale(data: SenToCash): Promise<AxiosResponse<boolean>>{
        return  ApiService.put(this.apiController + `/${data.saleId}/SendToPay`
            , data
        ).then( resp => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async senToSale(sale: SenToSale): Promise<AxiosResponse<boolean>>{
        return  ApiService.put(this.apiController + `/${sale.id}/ChangeToSale`
            , sale
        ).then( resp => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }

    async senToSchedule(sale: SenToCash): Promise<AxiosResponse<boolean>>{
        return  ApiService.put(this.apiController + `/${sale.saleId}/SendToSchedule`
            , sale
        ).then( resp => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async searchTableBillProducts(filters: FiltersSearchToBill): Promise<AxiosResponse<SaleInfoWithBillInfo>> {
        return  ApiService.get<SaleInfoWithBillInfo>(this.apiController, 
            `${filters.folio ?? ''}/GetSaleByInvoice?`
            + `amount=${filters.import ?? 0}`
        ).then( resp => {
            //la respuesta en este punto trae datos de mas... si necesitas mas datos que ya trae
            //la consulta puedes cambiar la respuesta de est método...
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async GetQuotesView(id: string): Promise<AxiosResponse<boolean>>{
        return  ApiService.get<boolean>(this.apiController + `/GetQuotesView/${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async GetQuotesCompleted(id: string): Promise<AxiosResponse<boolean>>{
        return  ApiService.get<boolean>(this.apiController + `/GetQuotesCompleted/${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async GetReceiptView(id: string): Promise<AxiosResponse<boolean>>{
        return  ApiService.get<boolean>(this.apiController + `/GetReceiptView/${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

}