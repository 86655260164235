export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "BUTTONS": {
          "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])}
        }
      }
    }
  })
}
