import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-13 text-center" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "text-gray-400 fw-bold fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BillProductsForm = _resolveComponent("BillProductsForm")!
  const _component_DynamicContentModal = _resolveComponent("DynamicContentModal")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_DynamicContentModal, {
      "target-id": 'new-bill-sale',
      onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cleanForm())),
      width: 1000
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, [
            _createElementVNode("span", null, [
              (_ctx.isGlobalBill)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t("TITLES.BILL_GLOBAL")), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t("TITLES.BILL_FOR_SALE")), 1)
                  ], 64))
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("SUB_TITLE_BILL", { amount: _ctx.formatNumeric(_ctx.amountToBill)})), 1)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_BillProductsForm)
      ]),
      _: 1
    })
  ]))
}